import React from 'react';
import '../styles/Timeline.css'; 

const Timeline = () => {
  return (
    <div className="timeline-section">
      <div className="timeline-header">
        <h2>Timeline</h2>
        <h3>Kyle Zubricky's Tennis Journey</h3>
      </div>
      <div className="timeline">
        {/* Timeline Container 1 */}
        <div className="timeline-container right-container">
          <div className="timeline-circle"></div>
          <div className="timeline-body">
            <h2>Started Playing Tennis</h2>
            <small>2012</small>
            <p>Began my journey at a very young age.</p>
            <span className="right-container-arrow"></span>
          </div>
        </div>

        {/* Timeline Container 2 */}
        <div className="timeline-container left-container">
          <div className="timeline-circle"></div>
          <div className="timeline-body">
            <h2>High School Tennis</h2>
            <small>2014-2018</small>
            <p>Played tennis for Westlake High School.</p>
            <span className="left-container-arrow"></span>
          </div>
        </div>

        {/* Timeline Container 3 */}
        <div className="timeline-container right-container">
          <div className="timeline-circle"></div>
          <div className="timeline-body">
            <h2>Graduated College</h2>
            <small>2022</small>
            <p>Graduated from the <strong>University of Akron</strong> with a degree in Marketing.</p>
            <span className="right-container-arrow"></span>
          </div>
        </div>

        {/* Timeline Container 4 */}
        <div className="timeline-container left-container">
          <div className="timeline-circle"></div>
          <div className="timeline-body">
            <h2>Started Coaching Tennis</h2>
            <small>2024</small>
            <p>Discovered my passion for coaching tennis.</p>
            <span className="left-container-arrow"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
