import React from 'react';
import '../styles/AboutSection.css';
import InstructorImage from '../assets/kyle1.jpg';
import Timeline from './Timeline';
import UTRlink from '../assets/StatsUtr.png';

const AboutSection = () => {
  return (
    <section className="about-section">
      {/* About Wrapper and Timeline side by side */}
      <div className="about-wrapper">
        <div className="about-content">
          <div className="about-left">
            <img src={InstructorImage} alt="Kyle Zubricky" className="instructor-image" />
          </div>
          <div className="about-right">
            <div className="about-text">
              <h2>Owner & Instructor</h2>
              <h3>Kyle Zubricky</h3>
              <p>
              I started playing tennis when I was 12. I played all 4 years in high school for Westlake. In 2023, I won the NTRP Sectional Midwest Championship, qualifying me for the National Tournament in San Diego. I still play competitively year-round in USTA tournaments and leagues. In 2024, I started coaching tennis part-time.
              </p>
            </div>
            <div className="stats-container">
              <h4>Verified Singles Ranking (Male)</h4>
              <div className="stats-grid">
                <div className="stat-item">
                  <span>#6,393</span>
                  <p>United States <em>Adult</em></p>
                </div>
                <div className="stat-item">
                  <span>#11,904</span>
                  <p>United States</p>
                </div>
                <div className="stat-item">
                  <span>#45,663</span>
                  <p>World <em>Adult</em></p>
                </div>
                <div className="stat-item">
                  <span>#68,387</span>
                  <p>World</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="images-container">
          <div className="utr-link-image">
            <h4>UTR Sports Stats</h4>
            <a href="https://app.utrsports.net/profiles/311638?t=4">
              <img src={UTRlink} alt="UTR Stats" />
            </a>
          </div>
        </div>
      </div>

      {/* Timeline aligned next to the about-wrapper */}
      <div className="timeline-wrapper">
        <Timeline />
      </div>
    </section>
  );
};

export default AboutSection;


